import React from "react";
import SignOutButton from "./authentication/SignOutButton";


const Portal = ({title, children}) => {

    return (
        <div className='flex flex-col min-h-screen'>


            <div className="flex flex-row flex-grow">
                {/*<div className="flex flex-col bg-gray-200 w-44  text-white" style={{backgroundImage: 'linear-gradient(to right, #051d39, #1e344d)'}}>*/}
                {/*    <div className="h-20">*/}
                {/*        LOGO*/}
                {/*    </div>*/}
                {/*    <ul>*/}

                {/*        <li><Link to="/tickets/new">New ticket</Link></li>*/}
                {/*        <li><Link to="/tickets">Tickets</Link></li>*/}
                {/*        <li><Link to="/invoices">Invoices</Link></li>*/}
                {/*        <li><Link to="/residents">Bewoners</Link></li>*/}
                {/*        <li><Link to="/treatments">behandelingen</Link></li>*/}
                {/*    </ul>*/}
                {/*</div>*/}


                <div className="flex flex-col w-screen bg-gray-100">

                    <div className="h-20 bg-white border-b border-gray-400 flex justify-between items-center p-4">
                        <div className="flex flex-col font-bold">
                            {title}
                        </div>
                        <div>
                            <SignOutButton />
                        </div>
                    </div>

                    <div className="h-full p-2 sm:p-5">
                        {children}
                    </div>

                </div>
            </div>

            {/*<div className="bg-gray-100 h-30">Footer</div>*/}


        </div>
    )
};

export default Portal
