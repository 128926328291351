import React, {useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from 'graphql-tag';
import {useHistory} from "react-router-dom";
import {SESSION_SET_TOKEN} from "../../session";
import {navigate} from "../../navigation";

export const AUTH_MUTATION = gql`
    mutation TokenAuth($username: String!, $password: String!) { 
        tokenAuth(username: $username, password: $password) { 
           token
        }
    }`;


export const SignInForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("")
    const [loginUserMutation] = useMutation(AUTH_MUTATION);
    const [setTokenMutation] = useMutation(SESSION_SET_TOKEN);

    let history = useHistory();


    const handleLogin = (e) => {
        e.preventDefault();

        loginUserMutation({
            variables: {
                username: username,
                password: password,
            }
        }).then(({data}) => {
            const {token} = data.tokenAuth;
            setTokenMutation({
                variables: {
                    token: token,
                }
            }).then(() => {
                navigate.dashboard()
            });
        }).catch((err) => {
            setError(err.message)
        })
    };

    return (
        <section className="py-8">
            <div className="w-full max-w-sm mx-auto">
                {error && error !== "" &&
                <div>{error}></div>}
                <form>
                    <div className="mb-4">
                        <input
                            className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                            type="text" placeholder="Email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value.toLowerCase())}/>
                    </div>
                    <div className="mb-4">
                        <input
                            className="appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                            type="password" placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <div className="mb-4">
                        <button className="inline-block w-full py-4 px-8 leading-none text-white bg-indigo-500 hover:bg-indigo-600 rounded shadow"
                                onClick={handleLogin}>Aanmelden
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
};

export default SignInForm;
