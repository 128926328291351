import React from "react";
import Portal from "../components/Portal";


const TreatmentsPage = () => {

    return (
        <Portal>
            <div> Behandelingen </div>
        </Portal>

    )
};

export default TreatmentsPage;
