import {createHttpLink} from "apollo-link-http";
import {onError} from "apollo-link-error";
import cogoToast from "cogo-toast";
import {initSession, resetSessionToDefault, resolvers as sessionResolvers} from "./session";
import {InMemoryCache} from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import {ApolloLink} from "apollo-link";
import gql from 'graphql-tag';


const link = createHttpLink({
    uri: '/graphql',
    credentials: 'same-origin'
});

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
        graphQLErrors.map((error) => {
            if (error.code && error.code === "401" || error.code === "403") {

                resetSessionToDefault(client);
            } else if (error.message && error.message !== "") {
                cogoToast.error(error.message);
            }
        })
    }
    if (networkError) {
        // Here you may display a message to indicate network error
        console.log(`[Network error]: ${networkError}`);
    }
});

const typeDefs = gql`              
        type Session {   
            id: ID!       
            token: String!    
            lastLogin: Date!,            
        }`;

const resolvers = {
    ...sessionResolvers,
};

const cache = new InMemoryCache();
cache.writeData({
    data: {
        session: initSession(),
    }
});

const client = new ApolloClient({
    cache: cache,
    link: ApolloLink.from([errorLink, link]),
    typeDefs: typeDefs,
    resolvers: resolvers,
    connectToDevTools: true,
});

export default client;
