import React from 'react';
import SignInForm from "../components/authentication/SignInForm";

const Login = () => {
    return (
        <SignInForm/>
    )
};

export default Login;
