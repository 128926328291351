import React from "react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {formatPrice} from "../../utils/functions";
import moment from "moment";

export const TicketsQuery = gql`
  query Tickets($start: Date!, $stop: Date!) {
    tickets(start: $start, stop: $stop) {
      id,
      date    
      name
      room
      details {
        description
        price
        vatRate
      }  
    }
  }
`;


const TicketsToday = () => {
    const {loading, data} = useQuery(TicketsQuery, {
        variables: {
            start: moment().format('YYYY-MM-DD'),
            stop: moment().format('YYYY-MM-DD'),
        }
    });


    if (loading || data === undefined) {
        return <div>Loading...</div>
    }

    return (
        <div>

            {data.tickets.length === 0 &&
            <div>
                Nog geen tickets
            </div>}

            <React.Fragment>
                {data.tickets.map((ticket) => {
                    const total = ticket.details.reduce((prev, next) => prev + (next.price * ((next.vatRate + 100) / 100)), 0);

                    return (
                        <div className="flex bg-gray-200 mb-2 p-2 justify-between items-center">
                            <div className="flex flex-col">
                                <div className="font-semibold font-medium">
                                    {ticket.name} - {ticket.room}
                                </div>

                                <div className="flex flex-wrap">
                                    {ticket.details.map((detail, i) => {
                                        return (
                                            <div className="">
                                                {detail.description} {(i < (ticket.details.length - 1)) ? '  +  ' : ''}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <span className="text-green-900 font-bold text-lg"> {formatPrice(total)} </span>
                            </div>
                        </div>
                    )
                })}
            </React.Fragment>

        </div>
    )
};

export default TicketsToday;
