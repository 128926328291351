import React from "react";
import Portal from "../components/Portal";


function open(url) {
    const win = window.open(url, '_blank');
    if (win != null) {
        win.focus();
    }
}

const InvoicesPage = () => {

    return (
        <Portal>
            <div> invoices </div>
        </Portal>

    )
};

export default InvoicesPage;
