import numeral from 'numeral';
import "numeral/locales";
numeral.locale('nl-be');


export const calcPriceIncl = (price, vatRate) => {
    return (price * ((vatRate + 100) / 100));
};

export const formatPrice = (price, currency = true) => {
    const format = (currency) ? '$0,0.00' : '0,00';
    return `${numeral(price).format(format)}`;
};

export const capitalize = (word) => {
    return word
        .toLowerCase()
        .replace(/\w/, firstLetter => firstLetter.toUpperCase());
}
