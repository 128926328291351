import React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {resetSessionToDefault} from "../../session";
import {withApollo} from "@apollo/react-hoc";

const AUTH_DELETE_COOKIE = gql`
mutation {
   deleteTokenCookie {
    deleted
  }
}`;

const SignOutButton = ({client}) => {
    const [deleteCookieMutation] = useMutation(AUTH_DELETE_COOKIE);

    const handleLogout = (client) => {
        deleteCookieMutation().then(() => {
            client.resetStore().then(() => {
                resetSessionToDefault(client);
            });
        });
    };

    return (
        <div className="cursor-pointer" onClick={() => handleLogout(client)}>
            <i className="fas fa-sign-out-alt"></i> Sign Out
        </div>
    )
};

export default withApollo(SignOutButton);
