import {history, paths} from './common'

export const handleNavigation = (path, params) => {
    let fullPath = path;
    if (params) {
        // TODO
        fullPath = ''
    }

    history.push({
        pathname: fullPath,
    });
};

export const navigate = {
    dashboard: () => handleNavigation(paths.DASHBOARD),
    login: () => handleNavigation(paths.LOGIN),
    tickets: () => handleNavigation(paths.TICKETS),
    ticketsCreate: () => handleNavigation(paths.TICKETS_NEW),
    invoices: () => handleNavigation(paths.INVOICES),
    residents: () => handleNavigation(paths.RESIDENTS),
    treatments: () => handleNavigation(paths.TREATMENTS),
};
