import React, {useEffect, useState} from "react";
import gql from "graphql-tag";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import moment from 'moment';
import DatePicker from "react-date-picker";
import {formatPrice} from "../../utils/functions";

export const TicketsQuery = gql`
  query Tickets($start: Date!, $stop: Date!) {
    tickets(start: $start, stop: $stop) {
      id,
      date    
      name
      room
      details {
        description
        price
        vatRate
      }  
    }
  }
`;


const TicketOverview = () => {
    const [date, setDate] = useState(new Date());
    const [getTickets, {loading, data}] = useLazyQuery(TicketsQuery);

    useEffect(() => {
        getTickets({
            variables: {
                start: moment(date).format('YYYY-MM-DD'),
                stop: moment(date).format('YYYY-MM-DD'),
            }
        })
    }, [date]);


    if (loading || data === undefined) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <DatePicker
                value={date}
                onChange={(date) => setDate(date)}
                format="dd-MM-y"
                clearIcon=""
                className="bg-gray-100 text-gray-900 border rounded py-1 px-2 mb-3 focus:outline-none focus:bg-white focus:border-gray-500"
            />

            {data.tickets.length === 0 &&
            <div>
                Nog geen tickets
            </div>}

            <React.Fragment>
                {data.tickets.map((ticket) => {
                    const total = ticket.details.reduce((prev, next) => prev + (next.price * ((next.vatRate + 100) / 100)), 0);

                    return (
                        <div className="flex bg-gray-200 mb-2 p-2 justify-between items-center">
                            <div className="flex flex-col">
                                <div className="font-semibold font-medium">
                                    {ticket.name} - {ticket.room}
                                </div>

                                <div className="flex flex-wrap">
                                    {ticket.details.map((detail, i) => {
                                        return (
                                            <div className="">
                                                {detail.description} {(i < (ticket.details.length - 1)) ? '  +  ' : ''}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <span className="text-green-900 font-bold text-lg"> {formatPrice(total)} </span>
                            </div>
                        </div>
                    )
                })}
            </React.Fragment>

        </div>
    )
};

export default TicketOverview;
