import React from "react";
import './styles/app.css'
import {Router} from './navigation'
import {ApolloProvider} from '@apollo/react-hooks';
import client from './apollo';

const App = () => {
    return (
        <ApolloProvider client={client}>
            <Router />
        </ApolloProvider>
    );
};


export default App;
