import {v4} from "uuid";
import moment from "moment";

const LOCAL_STORAGE_KEY = 'KS_ADMIN';

export const session_default = {
    __typename: 'Session',
    id: v4(),
    lastLogin: null,
    token: null,
};

export const sessionFromStorage = () => {
    const fromStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    return {
        ...session_default,
        ...fromStorage,
    }
};

export const sessionToStorage = (session) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        id: session.id,
        lastLogin: session.lastLogin
    }));
};

export const clearStorage = () => {
    try {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    } catch (error) {
        localStorage.clear();
    }
};

export const initSession = () => {
    const session_from_storage = sessionFromStorage();
    if (session_from_storage !== null) {
        return session_from_storage;
    } else {
        return session_default;
    }
};

export const resetSessionToDefault = (client) => {
    clearStorage();
    client.cache.writeData({data: {session: session_default}});
};


export const isAuthenticated = (session) => {
    const lastLogin = session.lastLogin;
    const expireDate = moment().add(1, 'days');  // Tokens are 1 day valid

    return (lastLogin && (expireDate > moment(lastLogin)))
};
