import React from "react";
import Portal from "../components/Portal";
import {default as Overview} from "../components/invoicing/TicketOverview"

const TicketOverview = () => {

    return (
        <Portal>
           <Overview />
        </Portal>

    )
};

export default TicketOverview;
