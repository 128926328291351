import gql from "graphql-tag";

export const GET_SESSION = gql`
  {  
    session @client {
       id
       lastLogin           
    }
  }
`;

export const SESSION_SET_TOKEN = gql`
  mutation setToken($token: String!) {
    setToken(token: $token) @client
  }
`;

export const SESSION_CLEAR = gql`
  mutation clearSession {
    clearSession @client
  }
`;


