import React, {useState} from "react";
import cogoToast from 'cogo-toast';
import {useLazyQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";

export const CustomerSearchQuery = gql`
    query Residents($filter: String!) { 
        residents(filter: $filter) { 
            id
            firstName
            lastName
            roomType
            roomFloor
            roomNumber
            gender
            fullName
            room
        }
    }`;

const ResidentsSearch = ({onChange}) => {
    const [searchString, setSearchString] = useState("");
    const [residentData, setResidentData] = useState([]);
    const [fetchCustomersQuery] = useLazyQuery(CustomerSearchQuery, {
            fetchPolicy: 'network-only',
            onCompleted: (result) => {
                setResidentData(result.residents);
            },
            onError: () => {
                cogoToast.error('Probleem bij het zoeken van bewoners');
            }
        })
    ;


    const handleSearch = (value) => {
        setSearchString(value);

        if (value.length >= 3) {
            fetchCustomersQuery({variables: {filter: value}});
        }
    };

    const handleOnResidentSelect = (customer) => {
        setResidentData([]);
        setSearchString("");
        onChange(customer);
    };

    return (
        <div className="relative">
            <input value={searchString}
                   onChange={(e) => handleSearch(e.target.value)}
                   className="relative bg-gray-200 appearance-none w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none"
                   type="text"
                   placeholder="Klant zoeken"
                   autoFocus={true}/>


            {((residentData.length > 0) && (searchString.length >= 3)) &&
            <div className="absolute w-full border border-gray-300 border-t-0" style={{top: 30, zIndex: 999, left: -1, right: 2}}>
                {residentData.map((resident, i) => {
                    return (
                        <div className="h-8 bg-white cursor-pointer border-b border-gray-300 h-10 flex items-center p-5 hover:bg-gray-100" onClick={() => handleOnResidentSelect(resident)}>
                            <span>{`${resident.fullName}  ( ${resident.room} )`} </span>
                        </div>
                    )
                })}
            </div>}

        </div>
    )
};

export default ResidentsSearch;
