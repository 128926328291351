import {createBrowserHistory} from "history";

const DASHBOARD = '/';
const LOGIN = '/login';
const TICKETS = '/tickets';
const TICKETS_NEW = '/tickets/new';
const INVOICES = '/invoices';
const RESIDENTS = '/residents';
const TREATMENTS = '/treatments';

export const paths = {
    DASHBOARD,
    LOGIN,
    TICKETS,
    TICKETS_NEW,
    INVOICES,
    RESIDENTS,
    TREATMENTS,
};


export const history = createBrowserHistory();
