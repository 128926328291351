import React, {useState} from "react";
import NewTickForm from "../components/invoicing/NewTickForm";
import Portal from "../components/Portal";
import TicketsToday from "../components/invoicing/TicketsToday";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import moment from "moment";

export const TicketsCountQuery = gql`
  query Tickets($start: Date!, $stop: Date!) {
    tickets(start: $start, stop: $stop) {
      id
    }
  }
`;

const NewTicketPage = () => {
    const [state, setState] = useState(0);
    const {loading, data} = useQuery(TicketsCountQuery, {
        variables: {
            start: moment().format('YYYY-MM-DD'),
            stop: moment().format('YYYY-MM-DD'),
        }
    } );


    const handleSwitchView = () => {
        if (state === 0)
            setState(1);
        else
            setState(0);
    };

    if(loading) {
        return null;
    }

    return (
        <Portal title="Nieuw ticket">

            <div className="h-10 bg-green-200 cursor-pointer mb-2 flex items-center p-4" onClick={handleSwitchView}>
                {state === 0 &&
                <span> Vandaag: {(data.tickets.length > 0) ? data.tickets.length : 0}</span>}

                {state === 1 &&
                <span> Terug </span>}


            </div>


            {state === 0 &&
            <NewTickForm onCreated={(ticket) => {

            }}/>}

            {state === 1 &&
            <TicketsToday/>}


        </Portal>

    )
};

export default NewTicketPage;
