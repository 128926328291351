import React from "react";
import {Router as ReactRouter, Route, Switch} from "react-router";
import Login from "../pages/Login";
import TicketOverview from "../pages/TicketOverview";
import InvoicesPage from "../pages/Invoices";
import ResidentsPage from "../pages/Residents";
import NewTicketPage from "../pages/TicketNew";
import TreatmentsPage from "../pages/Treatments";
import PrivateRoute from "./PrivateRoute";
import {history, paths} from './common'

export const Router = () => {
    return (
        <ReactRouter history={history}>
            <Switch>
                <Route path={paths.LOGIN}>
                    <Login/>
                </Route>
                <PrivateRoute path={paths.DASHBOARD} exact={true}>
                    <NewTicketPage/>
                </PrivateRoute>
                <PrivateRoute path={paths.INVOICES}>
                    <InvoicesPage/>
                </PrivateRoute>
                <PrivateRoute path={paths.RESIDENTS}>
                    <ResidentsPage/>
                </PrivateRoute>
                <PrivateRoute path={paths.INVOICES}>
                    <InvoicesPage/>
                </PrivateRoute>
                <PrivateRoute path={paths.TICKETS} exact={true}>
                    <TicketOverview/>
                </PrivateRoute>
                <PrivateRoute path={paths.TICKETS_NEW} exact={true}>
                    <NewTicketPage/>
                </PrivateRoute>
                <PrivateRoute path={paths.TREATMENTS}>
                    <TreatmentsPage/>
                </PrivateRoute>
            </Switch>
        </ReactRouter>
    )
};

export default Router;
