import React, {useState} from 'react';
import gql from 'graphql-tag';
import ResidentsSearch from "../residents/ResidentsSearch";
import cogoToast from "cogo-toast";
import DatePicker from 'react-date-picker';
import {calcPriceIncl, capitalize, formatPrice} from "../../utils/functions";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {TicketsCountQuery} from "../../pages/TicketNew";
import moment from "moment";

export const DataQuery = gql`{
    treatments {
        id
        price
        vatRate
        gender
        name
        priority
    }
    
    products {
        id
        description
        price
        vatRate
    }
}`;

export const ResidentCreateMutation = gql`
    mutation ResidentCreate($data: ResidentInputType!) { 
        residentCreate(data: $data) { 
            ok
            residentId
        }
    }`;


export const TicketInitializeMutation = gql`
    mutation TicketInitialize($data: TicketInputType!) { 
        ticketInitialize(data: $data) { 
            ok
            ticket {
              id
              date    
              name
              room
              details {
                description
                price
                vatRate
              }  
            }
        }
    }`;

const initialCustomer = {
    id: undefined,
    firstName: "",
    lastName: "",
    roomType: "",
    roomFloor: "",
    roomNumber: "",
    gender: undefined,
};

const NewTickForm = () => {
        const {loading, data} = useQuery(DataQuery);
        const [date, setDate] = useState(new Date());
        const [resident, setResident] = useState(initialCustomer);
        const [treatments, setTreatments] = useState([]);
        const [products, setProducts] = useState([]);
        const [saveTicketMutation] = useMutation(TicketInitializeMutation, {
            update(cache, { data: { ticketInitialize } }) {
                const { tickets } = cache.readQuery({ query: TicketsCountQuery, variables: {
                        start: moment().format('YYYY-MM-DD'),
                        stop: moment().format('YYYY-MM-DD'),
                    } });
                cache.writeQuery({
                    query: TicketsCountQuery,
                    variables: {
                        start: moment().format('YYYY-MM-DD'),
                        stop: moment().format('YYYY-MM-DD'),
                    },
                    data: { tickets: tickets.concat([ticketInitialize.ticket]) },
                });
            }
        });
        const [createResidentMutation] = useMutation(ResidentCreateMutation);
        const [formError, setFormError] = useState("");
        const [allTreatmentsVisible, setAllTreatmentsVisible] = useState(false);
        const [allProductsVisible, setAllProductsVisible] = useState(false);

        const handleSetResident = (customer) => {
            setResident({
                id: customer.id,
                firstName: customer.firstName,
                lastName: customer.lastName,
                roomType: customer.roomType,
                roomFloor: customer.roomFloor,
                roomNumber: customer.roomNumber,
                gender: customer.gender,
            });
        };

        const resetAll = () => {
            setResident(initialCustomer);
            setTreatments([]);
            setProducts([]);
            setFormError("");
            setAllTreatmentsVisible(false);
            setAllProductsVisible(false);
        };

        const handleSave = (e) => {
            e.preventDefault();

            if (!date ||
                (treatments.length === 0 && products.length === 0) ||
                resident.firstName === "" ||
                resident.lastName === "" ||
                resident.roomType === "" ||
                resident.roomFloor === "" ||
                resident.roomNumber === "") {

                setFormError("Niet alle velden zijn ingevuld");
                return;
            }

            if (!resident.id) {
                // first create resident
                createResidentMutation({
                        variables: {
                            data: {
                                firstName: resident.firstName,
                                lastName: resident.lastName,
                                gender: resident.gender,
                                roomType: resident.roomType,
                                roomFloor: resident.roomFloor,
                                roomNumber: resident.roomNumber,
                            },
                        }
                    }
                ).then((result) => {
                    const {ok, residentId} = result.data.residentCreate;
                    if (ok && residentId > 0) {
                        setResident({...resident, id: residentId});
                        handleSaveTicket(residentId);
                    }
                })
            } else {
                handleSaveTicket(resident.id);
            }
        };

        const handleSaveTicket = (residentId) => {
            saveTicketMutation({
                variables: {
                    data: {
                        residentId: residentId,
                        date: date,
                        treatments: treatments.map((treatment) => treatment.id),
                        products: products.map((product) => product.id),
                    },
                }
            }).then(res => {
                const {ok, ticket} = res.data.ticketInitialize;
                if (ok && ticket.id > 0) {
                    cogoToast.success("Created")
                    resetAll();
                }


            }).catch(error => {
                cogoToast.error('Error!');
            });
        };


        const handleSelectTreatment = (treatment) => {
            if (treatments.find(x => x.id === treatment.id)) {
                const copy = [...treatments];
                const result = copy.filter((t) => t.id !== treatment.id);
                setTreatments(result);
            } else {
                const copy = [...treatments];
                copy.push(treatment);
                setTreatments(copy);
            }
        };

        const handleSelectProduct = (product) => {
            if (products.find(x => x.id === product.id)) {
                const copy = [...products];
                const result = copy.filter((t) => t.id !== product.id);
                setProducts(result);
            } else {
                const copy = [...products];
                copy.push(product);
                setProducts(copy);
            }
        };

        if (loading || data === undefined) {
            return <div>Loading...</div>
        }

        const allTreatments = (resident && resident.gender) ? data.treatments.filter((treatment) => treatment.gender === resident.gender) : data.treatments;

        const totalTreatments = treatments.reduce((prev, next) => prev + (next.price * ((next.vatRate + 100) / 100)), 0);
        const totalProducts = products.reduce((prev, next) => prev + (next.price * ((next.vatRate + 100) / 100)), 0);
        const total = totalTreatments + totalProducts;

        return (
            <div className="bg-white p-2 sm:p-5">
                <div className="mb-2 md:mb-4 lg:md:6 xl:md-6">
                    <ResidentsSearch onChange={(customer) => handleSetResident(customer)}/>
                </div>

                <form className="w-full max-w-lg" onSubmit={handleSave}>
                    <div className="">
                        <div className="flex flex-wrap -mx-3 mb-2 justify-between">
                            <div className="w-1/2 md:w-4/12 px-3">
                                <div className="w-full">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-floor">
                                        Kamer
                                    </label>
                                    <div className="flex">
                                        <input
                                            className="flex-col appearance-none block w-full bg-gray-100 text-gray-900 border rounded py-1 px-2 focus:outline-none focus:bg-white focus:border-gray-50"
                                            id="grid-floor"
                                            type="text"
                                            placeholder="K"
                                            value={resident.roomType}
                                            style={{width: 38}}
                                            onChange={(e) => setResident({...resident, id: undefined, roomType: e.target.value.toUpperCase()})}
                                        />
                                        <span className="px-2 font-bold">-</span>
                                        <input
                                            className="flex-col appearance-none block w-full bg-gray-100 text-gray-900 border rounded py-1 px-2 focus:outline-none focus:bg-white focus:border-gray-50"
                                            id="grid-floor"
                                            type="text"
                                            placeholder="1"
                                            value={resident.roomFloor}
                                            style={{width: 30}}
                                            onChange={(e) => setResident({...resident, id: undefined, roomFloor: e.target.value})}
                                        /><span className="px-2 font-bold flex items-end">.</span>
                                        <input
                                            className="flex-col appearance-none block w-full bg-gray-100 text-gray-900 border rounded py-1 px-2 focus:outline-none focus:bg-white focus:border-gray-50"
                                            id="grid-room"
                                            type="text"
                                            placeholder="38"
                                            value={resident.roomNumber}
                                            style={{width: 38}}
                                            onChange={(e) => setResident({...resident, id: undefined, roomNumber: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-1/2 md:w-3/12 px-3 ">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-right md:text-left" htmlFor="grid-floor">
                                    Geslacht
                                </label>
                                <div className="flex justify-end">
                                    <div
                                        className={`flex flex-col w-12 mr-4 bg-gray-100 justify-center items-center cursor-pointer ${(resident.gender === 'M') ? 'text-gray-700 border border-gray-900 ' : 'text-gray-500'}`}
                                        onClick={() => setResident({...resident, id: undefined, gender: 'M'})}
                                        style={{minHeight: 38}}>
                                        <i className="fas fa-male text-xl"></i>
                                    </div>
                                    <div
                                        className={`flex flex-col w-12 bg-gray-100 justify-center items-center cursor-pointer ${(resident.gender === 'F') ? 'text-gray-700 border border-gray-900 ' : 'text-gray-500'}`}
                                        onClick={() => setResident({...resident, id: undefined, gender: 'F'})}
                                        style={{minHeight: 38}}>
                                        <i className="fas fa-female text-xl"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full md:w-1/2 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Voornaam
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-100 text-gray-900 border rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-first-name"
                                    type="text"
                                    placeholder="Voornaam"
                                    value={resident.firstName}
                                    onChange={(e) => setResident({...resident, id: undefined, firstName: capitalize(e.target.value)})}
                                />
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                    Naam
                                </label>
                                <input
                                    className="appearance-none block w-full bg-gray-100 text-gray-900 border rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name"
                                    type="text"
                                    placeholder="Naam"
                                    value={resident.lastName}
                                    onChange={(e) => setResident({...resident, id: undefined, lastName: capitalize(e.target.value)})}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Datum behandeling
                            </label>
                            <DatePicker
                                value={date}
                                onChange={(date) => setDate(date)}
                                format="dd-MM-y"
                                clearIcon=""
                                className="bg-gray-100 text-gray-900 border rounded py-1 px-2 mb-3 focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>


                    <div className="multi-column w-full mb-2">
                        <div className="flex justify-between">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                Uitgevoerde behandelingen:
                            </label>
                            <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2 cursor-pointer"
                                   onClick={() => setAllTreatmentsVisible(!allTreatmentsVisible)}>
                                Alle behandelingen
                            </label>

                        </div>

                        <div className="flex flex-wrap">
                            {allTreatments.filter((t) => t.priority > 0).map((treatment, i) => {
                                const selected = treatments.find(x => x.id === treatment.id);
                                const price = calcPriceIncl(treatment.price, treatment.vatRate);

                                return (
                                    <div className={`flex w-full sm:w-1/2 cursor-pointer ${(i % 2 === 0) ? "sm:pr-2" : ""}`}
                                         onClick={() => handleSelectTreatment(treatment)}>
                                        <div className={`flex w-full justify-between border bg-gray-100 px-2 py-2 mb-2 ${selected ? 'border-gray-900' : 'border-gray-300'}`}>
                                            <div className="flex flex-col font-size-14 text-gray-900">{treatment.name}</div>
                                            <div className="flex flex-col font-size-12 text-gray-700">{formatPrice(price)}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {allTreatmentsVisible &&
                        <div className="flex flex-wrap border-t border-gray-600 pt-2">
                            {allTreatments.filter((t) => t.priority === 0).map((treatment, i) => {
                                const selected = treatments.find(x => x.id === treatment.id);
                                const price = calcPriceIncl(treatment.price, treatment.vatRate);

                                return (
                                    <div className={`flex w-full sm:w-1/2 cursor-pointer ${(i % 2 === 0) ? "sm:pr-2" : ""}`}
                                         onClick={() => handleSelectTreatment(treatment)}>
                                        <div className={`flex w-full justify-between border bg-gray-100 px-2 py-2 mb-2 ${selected ? 'border-gray-900' : 'border-gray-300'}`}>
                                            <div className="flex flex-col font-size-14 text-gray-900">{treatment.name}</div>
                                            <div className="flex flex-col font-size-12 text-gray-700">{formatPrice(price)}</div>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>}
                    </div>


                    {!allProductsVisible &&
                    <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2 mt-2 cursor-pointer"
                           onClick={() => setAllProductsVisible(true)}>
                        + Producten toevoegen
                    </label>}


                    {allProductsVisible &&
                    <div className="multi-column w-full">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            Producten:
                        </label>

                        <div className="flex flex-wrap">
                            {data.products.map((product, i) => {
                                const selected = products.find(x => x.id === product.id);
                                const price = calcPriceIncl(product.price, product.vatRate);

                                return (
                                    <div className={`flex w-full sm:w-1/2 cursor-pointer ${(i % 2 === 0) ? "sm:pr-2" : ""}`}
                                         onClick={() => handleSelectProduct(product)}>
                                        <div className={`flex w-full justify-between border bg-gray-100 px-2 py-2 mb-2 ${selected ? 'border-gray-900' : 'border-gray-300'}`}>
                                            <div className="flex  flex-col font-size-14 text-gray-900">{product.description}</div>
                                            <div className="flex flex-col font-size-12 text-gray-700">{formatPrice(price)}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>}


                    <div className="flex justify-end items-center px-4 py-2 border-t border-gray-500 mt-5">
                        <span className="text-green-800 block  uppercase tracking-wide mr-4 text-sm">Totaal:</span>
                        <span className="text-green-900 font-bold text-lg"> {formatPrice(total)} </span>
                    </div>


                    {formError !== "" &&
                    <div className="bg-red-200 text-red-900 p-2">
                        {formError}
                    </div>}
                    <input type="submit" value="Opslaan" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-5 cursor-pointer"/>


                </form>
            </div>

        )
    }
;


export default NewTickForm;
