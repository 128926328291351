import {isAuthenticated, useSession} from "../session";
import {Route} from "react-router";
import {Redirect} from "react-router-dom";
import React from "react";

const PrivateRoute = ({children, ...rest}) => {
    const session = useSession();
    return (
        <Route {...rest}
               render={({location}) =>
                   isAuthenticated(session) ? (
                       children
                   ) : (
                       <Redirect
                           to={{
                               pathname: "/login",
                               state: {from: location}
                           }}
                       />
                   )
               }
        />
    );
};

export default PrivateRoute;
