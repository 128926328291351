import {GET_SESSION} from "./graphql";
import {sessionToStorage, session_default, clearStorage} from './common';
import moment from "moment";

const writeToCache = (field, value, cache) => {
    const { session } = cache.readQuery({ query: GET_SESSION });
    const data = {
        ...session,
        __typename: 'Session',
        [field]: value,
    };
    cache.writeQuery({ query: GET_SESSION, data: {session: data} });
    sessionToStorage(data);
    return data;
};


export const setToken = (_, { token }, { cache }) => {
    const { session } = cache.readQuery({ query: GET_SESSION });
    const data = {
        ...session,
        __typename: 'Session',
        token: token,
        lastLogin: moment().format("YYYY-MM-DD HH:mm:ss")
    };
    cache.writeQuery({ query: GET_SESSION, data: {session: data} });
    sessionToStorage(data);
    return data;
};

export const setCulture = (_, { culture }, { cache }) => {
    return writeToCache('culture', culture, cache);
};

export const clearSession = (_, __, { cache }) => {
    cache.readQuery({ query: GET_SESSION });
    const data = {
        ...session_default
    };
    cache.writeQuery({ query: GET_SESSION, data: {session: data} });
    clearStorage(data);
    return data;
};

export const resolvers = {
    Mutation: {
        'setToken': setToken,
        'clearSession': clearSession,
    }
};
